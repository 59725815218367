.rc-card {
  overflow: hidden;
}
.rc-accordion-toggle {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  justify-content: space-between;
}
.rc-accordion-toggle.active .rc-accordion-icon{
  transform: rotate(180deg);
}
.rc-accordion-card{
  border: 2px solid #aaa;
  padding: 8px;
  border-radius: 9px;
  margin-bottom: 16px;
  overflow: hidden;
}
.rc-accordion-card:last-child{
  margin-bottom: 0;
}

.rc-accordion-icon{
  position: relative;
  color: #555;
  transition: 1s;
  font-size: 20px;
}
.rc-accordion-body{
  flex: 1 1 auto;
  min-height: 1px;
  padding: 15px;
}
.rc-collapse{
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 1s ease;
}
.rc-collapse.show{
  height: auto;
}