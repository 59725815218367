.icon-1 {
    visibility: visible; 
    animation-duration: 1s; 
    animation-name: fadeInUp;
}
.icon-2 {
    visibility: visible; 
    animation-duration: 1s; 
    animation-delay: 0.1s; 
    animation-name: fadeInUp;
}
.icon-3 {
    visibility: visible; 
    animation-duration: 1s; 
    animation-delay: 0.3s; 
    animation-name: fadeInUp;
}