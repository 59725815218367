.profile-card-leader {
    background-color: rgb(0, 0, 0, 0.6);
    opacity: 0;
    transition: all 0.5s;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid #c1c1c1;
}
.profile-card-leader:hover {
    opacity: 1;
}

.linkedin-logo-leader {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 1;
}

.profile-background-leader {
    border: 1px solid #ddd;
} 