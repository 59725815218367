.carousel {
  /* width: 100%; */
  height: 100vh;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
}

.carousel:hover .controls {
  opacity: 1;
}

.controls {
  opacity: 0;
  display: flex;
  position: absolute;
  top: 60%;
  left: 0;
  justify-content: space-between;
  width: 100%;
  z-index: 9000;
  transition: all ease 0.5s;
}
.control {
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.7);
  opacity: .5;
  transition: ease .3s;
  cursor: pointer;
}

.control:hover {
  opacity: 1;
}


.slides {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  width: 100%;
  transition: 1s ease-in-out all;
}
.slide {
  min-width: 100%;
  min-height: 250px;
  height: 100vh;
}
.slide img {
  height: 100vh;
}

.lotte_file {
  width: 350px;
  height: 350px;
}

.lotte_video {
  height: 315px;
  width: 560px;
}

@media (max-width: 600px) {
  .lotte_section {
    display: none;
  }
}