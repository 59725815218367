.pri-title {
    margin-top: 2em;

    text-align: center;
    font-weight: bold;
    font-size: xx-large;
    color: #444;
  }
  .pri-para {
    text-align: center;
    font-size: medium;
    padding: 0;
    padding-bottom: 1em;
  }
  .pri-line {
    height: 1px;
    box-sizing: border-box;
    background-color: rgb(206, 206, 206);
    max-width: 700px;
    margin: auto;
    margin-bottom: 2em;
  }
  .pri-content {
    width: 700px;
    margin: auto;
    text-align: left;
    padding-bottom: 6em;
  }
  .pri-content-list {
    margin: 0.5em;
    margin-left: 2em;
    text-indent: -1.3em;
  }
  .pri-content-para {
    margin-top: 1em;
  }
  .pri-content-heading {
    padding-top: 2em;
    font-weight: bold;
    font-size: x-large;
    color: #444;
  }
  @media (max-width: 1024px) {
    .pri-content {
      width: 600px;
      margin: auto;
    }
  }
  @media (max-width: 640px) {
    .pri-content {
      width: 300px;
      margin: auto;
    }
    .pri-content-para {
      font-size: small;
    }
    .pri-content-heading {
      font-size: medium;
    }
    .pri-title {
      font-size: x-large;
    }
    .pri-line {
      max-width: 300px;
    }
    .pri-content-list {
      font-size: small;
    }
    .pri-title {
      margin: auto;
      width: 300px;
    }
  }