@import url(https://fonts.googleapis.com/css?family=Lato:300,700,300italic);


.home_animation {
  align-items: center;
  background-image: linear-gradient(to right, #861a54,#054f7d,#00a7cf, #efe348);
  background-size: 600%;
  background-position: 0 0;
  display: flex;
  height: 100%;
  justify-content: center;
  /* Animation */
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-name: gradients;
}

@keyframes gradients {
    0%   {background-position: 0 0;}
    25% {background-position: 50% 0;}
    50% {background-position: 90% 0;}
    60% {background-position: 60%;}
    75% {background-position: 40%;}
    100%  {background-position: 0 0;}
}